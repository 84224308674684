<template>
  <div class="v-stack gap-3 h-stretch">
    <div class="card light">
      <div class="content h-stretch">
        <div class="v-stack gap-3 h-stretch">
          <label class="text-left required">Title</label>
          <input v-model="name" type="text" />
          <label class="text-left required">Amount</label>
          <input v-model="amount" type="number" />
          <label class="text-left">Description</label>
          <textarea v-model="description" />
          <div class="h-stack h-end">
            <button @click="submit()" class="submit">Add</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["id"],
  data() {
    return {
      name: "",
      amount: 0,
      description: "",
    };
  },
  methods: {
    ...mapActions(["addExpense"]),
    submit() {
      if (this.name.length > 0 && this.amount > 0) {
        this.addExpense({
          name: this.name,
          amount: this.amount,
          description: this.description,
          project: this.id,
        })
          .then(() => {
            this.$router.go(-1);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>
